<template>
  <div>収益管理</div>
</template>

<script>
export default {

}

</script>
<style>
</style>